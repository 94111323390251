<template>
  <el-dialog title="交易材料"  @close="onClose" :visible.sync="visible" custom-class="min-width" :close-on-click-modal="false" draggable>
    <el-form ref="dataFormRef" :model="form" :rules="dataRules" formDialogRef label-width="250px" v-loading="loading">
      <el-row :gutter="24">


        <el-col :span="12" class="mb20" v-if="form.personType!='seller'">
          <el-form-item label="统一社会信用代码" prop="unifiedSocialCreditCode">
            <el-input v-model="form.unifiedSocialCreditCode" placeholder="请输入统一社会信用代码" />
          </el-form-item>
        </el-col>


        <el-col :span="12" class="mb20" v-if="form.personType!='seller'">
          <el-form-item label="联系人地址" prop="contactAddress">
            <el-input v-model="form.contactAddress" placeholder="请输入联系人地址" />
          </el-form-item>
        </el-col>
        <el-col :span="12" class="mb10" v-if="form.personType=='seller'" >
          <el-form-item label="土地估价" prop="gujia">
            <el-input v-model="form.gujia"  placeholder="请输入土地估价">
              <template v-slot:append>万元</template>
            </el-input>
          </el-form-item>
        </el-col>
        <el-col :span="24" class="mb20">
          <el-divider>文件上传</el-divider>
        </el-col>
        <el-col :span="10" class="mb20">
          <el-form-item label="身份证明材料" prop="identityFiles">
            <div style="display: flex; flex-direction: row; column-gap: 12px">
              <div >
                <upload @childUpload="parentGetData" @on-preview="previewFile" name="identityFiles" />
              </div>
              <ul  class="navWrap_center_ul" style="width: 150px;">
                <li style="white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;"
                    v-for="(item, index) in form.identityFiles"
                    :key="index"
                    @click="downloadFile(item.url)"
                >
                  {{ item.name }}
                </li>
              </ul>
            </div>
          </el-form-item>
        </el-col>
        <el-col :span="10" class="mb20">
          <el-form-item label="委托文件" prop="proxyFiles">
            <div  >
              <div style="display: flex; flex-direction: row; column-gap: 12px">

                <upload  @childUpload="parentGetData" @on-preview="previewFile"  name="proxyFiles"  />
              </div>
            </div>
            <ul  class="navWrap_center_ul" style="width: 150px;">
              <li style="white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;"
                  v-for="(itemProxy, index) in form.proxyFiles"
                  :key="index"
                  @click="downloadFile(itemProxy.url)"
              >
                {{ itemProxy.name }}
              </li>
            </ul>
          </el-form-item>
        </el-col>
      </el-row>
      <el-row :gutter="24">
        <el-col :span="10" class="mb20">
          <el-form-item label="企业章程文件" prop="companyFiles">
            <div style="display: flex; flex-direction: row; column-gap: 12px"  >
              <upload @childUpload="parentGetData"  @on-preview="previewFile" name="companyFiles" />
            </div>
            <ul  class="navWrap_center_ul" style="width: 150px;">
              <li style="white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;"
                  v-for="(itemcompany, index) in form.companyFiles"
                  :key="index"
                  @click="downloadFile(itemcompany.url)" >
                {{ itemcompany.name }}
              </li>
            </ul>
          </el-form-item>
        </el-col>

        <el-col :span="10" class="mb20">
          <el-form-item label="股东会决议文件" prop="shareholderFiles">
            <div style="display: flex; flex-direction: row; column-gap: 12px" >
              <upload @childUpload="parentGetData"  @on-preview="previewFile" name="shareholderFiles" />
            </div>
            <ul class="navWrap_center_ul" style="width: 150px;">
              <li style="white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;"
                  v-for="(itemShareholder, index) in form.shareholderFiles"
                  :key="index"
                  @click="downloadFile(itemShareholder.url)" >
                {{ itemShareholder.name }}
              </li>
            </ul>
          </el-form-item>
        </el-col>
        <el-col :span="10" class="mb20">
          <el-form-item label="其他材料" prop="otherFiles">
            <div style="display: flex; flex-direction: row; column-gap: 12px" >
              <upload @childUpload="parentGetData"  @on-preview="previewFile" name="otherFiles" />
            </div>
            <ul  class="navWrap_center_ul" style="width: 150px;">
              <li style="white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;"
                  v-for="(otherFilesItem, index) in form.otherFiles"
                  :key="index"
                  @click="downloadFile(otherFilesItem.url)" >
                {{ otherFilesItem.name }}
              </li>
            </ul>
          </el-form-item>
        </el-col>

        <el-col :span="10" class="mb20">
          <el-form-item label="申请材料" prop="applyFiles">
            <div style="display: flex; flex-direction: row; column-gap: 12px"  >
              <upload @childUpload="parentGetData"  @on-preview="previewFile" name="applyFiles" />
            </div>
            <ul class="navWrap_center_ul" style="width: 150px;">
              <li style="white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;"
                  v-for="(itemApplyFiles, index) in form.applyFiles"
                  :key="index"
                  @click="downloadFile(itemApplyFiles.url)" >
                {{ itemApplyFiles.name }}
              </li>
            </ul>
          </el-form-item>
        </el-col>

        <el-col :span="10" class="mb20" >
          <el-form-item label="不动产登记信息卡(查询抵押、查封、冻结等情况)" prop="identityCards">
            <div style="display: flex; flex-direction: row; column-gap: 12px"  >
              <upload @childUpload="parentGetData"  @on-preview="previewFile" name="identityCards" />
            </div>
            <ul class="navWrap_center_ul" style="width: 150px;">
              <li style="white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;"
                  v-for="(itemIdentityCards, index) in form.identityCards"
                  :key="index"
                  @click="downloadFile(itemIdentityCards.url)" >
                {{ itemIdentityCards.name }}
              </li>
            </ul>
          </el-form-item>
        </el-col>

        <el-col :span="10" class="mb20">
          <el-form-item label="国有建设用地使用权出让合同" prop="landOutContract">
            <div style="display: flex; flex-direction: row; column-gap: 12px" >
              <upload @childUpload="parentGetData"  @on-preview="previewFile" name="landOutContract" />
            </div>
            <ul  class="navWrap_center_ul" style="width: 150px;">
              <li style="white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;"
                  v-for="(landOutContractItem, index) in form.landOutContract"
                  :key="index"
                  @click="downloadFile(landOutContractItem.url)" >
                {{ landOutContractItem.name }}
              </li>
            </ul>
          </el-form-item>
        </el-col>

        <el-col :span="10" class="mb20">
          <el-form-item label="评估报告" prop="landEvaluateReport">
            <div style="display: flex; flex-direction: row; column-gap: 12px" >
              <upload @childUpload="parentGetData"  @on-preview="previewFile" name="landEvaluateReport" />
            </div>
            <ul  class="navWrap_center_ul" style="width: 150px;">
              <li style="white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;"
                  v-for="(landEvaluateReportItem, index) in form.landEvaluateReport"
                  :key="index"
                  @click="downloadFile(landEvaluateReportItem.url)" >
                {{ landEvaluateReportItem.name }}
              </li>
            </ul>
          </el-form-item>
        </el-col>

        <el-col :span="10" class="mb20">
          <el-form-item label="资产评审报告" prop="assetsEvaluateReport">
            <div style="display: flex; flex-direction: row; column-gap: 12px"  >
              <upload @childUpload="parentGetData"  @on-preview="previewFile" name="assetsEvaluateReport" />
            </div>
            <ul  class="navWrap_center_ul" style="width: 150px;">
              <li style="white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;"
                  v-for="(assetsEvaluateReportItem, index) in form.assetsEvaluateReport"
                  :key="index"
                  @click="downloadFile(assetsEvaluateReportItem.url)" >
                {{ assetsEvaluateReportItem.name }}
              </li>
            </ul>
          </el-form-item>
        </el-col>
        <el-col :span="10" class="mb20">
          <el-form-item label="转让协议或合同" prop="transferContract">
            <div style="display: flex; flex-direction: row; column-gap: 12px"  >
              <upload @childUpload="parentGetData"  @on-preview="previewFile" name="transferContract" />
            </div>
            <ul  class="navWrap_center_ul" style="width: 150px;">
              <li style="white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;"
                  v-for="(transferContractItem, index) in form.transferContract"
                  :key="index"
                  @click="downloadFile(transferContractItem.url)" >
                {{ transferContractItem.name }}
              </li>
            </ul>
          </el-form-item>
        </el-col>

        <el-col :span="10" class="mb20">
          <el-form-item label="国有企业主管部门同意转让的批准文件" prop="permitFiles">
            <div style="display: flex; flex-direction: row; column-gap: 12px"  >
              <upload @childUpload="parentGetData"  @on-preview="previewFile" name="permitFiles" />
            </div>
            <ul class="navWrap_center_ul" style="width: 150px;">
              <li style="white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;"
                  v-for="(permitFilesItem, index) in form.permitFiles"
                  :key="index"
                  @click="downloadFile(permitFilesItem.url)" >
                {{ permitFilesItem.name }}
              </li>
            </ul>
          </el-form-item>
        </el-col>

        <el-col :span="10" class="mb20">
          <el-form-item label="成交确认书" prop="confirmFiles">
            <div style="display: flex; flex-direction: row; column-gap: 12px"  >
              <upload @childUpload="parentGetData"  @on-preview="previewFile" name="confirmFiles" />
            </div>
            <ul  class="navWrap_center_ul" style="width: 150px;">
              <li style="white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;"
                  v-for="(confirmFilesItem, index) in form.confirmFiles"
                  :key="index"
                  @click="downloadFile(confirmFilesItem.url)" >
                {{ confirmFilesItem.name }}
              </li>
            </ul>
          </el-form-item>
        </el-col>
        <el-col :span="10" class="mb20">
          <el-form-item label="公证书" prop="proofFiles">
            <div style="display: flex; flex-direction: row; column-gap: 12px"  >
              <upload @childUpload="parentGetData"  @on-preview="previewFile" name="proofFiles" />
            </div>
            <ul  class="navWrap_center_ul" style="width: 150px;">
              <li style="white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;"
                  v-for="(proofFilesItem, index) in form.proofFiles"
                  :key="index"
                  @click="downloadFile(proofFilesItem.url)" >
                {{ proofFilesItem.name }}
              </li>
            </ul>
          </el-form-item>
        </el-col>
        <el-col :span="10" class="mb20">
          <el-form-item label="司法裁定书" prop="judicialFiles">
            <div style="display: flex; flex-direction: row; column-gap: 12px"  >
              <upload @childUpload="parentGetData"  @on-preview="previewFile" name="judicialFiles" />
            </div>
            <ul  class="navWrap_center_ul" style="width: 150px;">
              <li style="white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;"
                  v-for="(judicialFilesItem, index) in form.judicialFiles"
                  :key="index"
                  @click="downloadFile(judicialFilesItem.url)" >
                {{ judicialFilesItem.name }}
              </li>
            </ul>
          </el-form-item>
        </el-col>

      </el-row>
    </el-form>
    <template #footer >
			<span class="dialog-footer" >
				<el-button @click="onClose">取消</el-button>
				<el-button type="primary" @click="onSubmit" :disabled="loading">确认</el-button>
			</span>
    </template>

  </el-dialog>
</template>

<script setup  name="agreementTradeFilesDialog">
import { useDict } from '@/hooks/dict';
import {Message} from 'element-ui'
import {reactive, ref, defineAsyncComponent, nextTick} from 'vue'
import  CommonService  from '@/service/CommonService';

const { transaction_person_type } = useDict('transaction_person_type');

const Upload = defineAsyncComponent(() => import('@/components/Upload/NewFile.vue'));

const submitStatus = ref(false)
const parentGetData = (value,name) => {
  submitStatus.value = true;
  form[name] = value;
};


// 定义变量内容
const dataFormRef = ref();
const visible = ref(false);
const loading = ref(false);


// 提交表单数据
const form = reactive({
  id: '',
  landId: '',
  personType: '',
  unifiedSocialCreditCode: '',
  actualSeller: '',
  contactAddress: '',
  actualBuyer: '',
  gujia:0,
  userId: localStorage.getItem('user_id'),

});

const previewFile = (file) => {
  window.open(file.url);
};
const onClose= () =>{
  stop();
  window.location.reload()
}

const downloadFile = (file) => {
  window.open(file);
};


const validatePrice = async (rule, value, callback) => {
  // console.log(value,typeof value , !isNaN(value));




  let exp = /^[+]?\d*(\.\d*)?(e[+-]?\d+)?$/;
  if(form.transactionWay === "auction"  || form.transactionWay ==="listing"  || form.personType=='seller') {
    if (!exp.test(value) || value <= 0 ) {
      callback(new Error('请输入价格'));
    }
  }
}

// 定义校验规则
const dataRules = ref({
  parentGetData: [{ required: true, message: '营业执照/机构代码/法人证明/法人身份证 不能为空', trigger: 'blur' }],
  gujia: [{required: true,validator: validatePrice, trigger: 'change' }],
  unifiedSocialCreditCode: [{ required: true, message: '请输入统一社会信用码', trigger: 'change' }]
 // contactAddress: [{ required: true, message: '请输入联系人地址', trigger: 'change' }]
});

let param_data=null;
// 打开弹窗
const openDialog = (param , type) => {

  console.log(param)
  form.personType = '';
  if (type === 'protocol' ||  type === 'rent') {
    form.personType = param.sellerId === localStorage.getItem('user_id') ? 'seller' : 'buyer';
    form.fileId = form.personType === 'seller' ? param.sellerIdentityId  : param.buyerIdentityId ;

  }else if(type === 'protocolnew') {
    form.personType = 'buyer';
    form.fileId = form.personType === 'seller' ? param.sellerIdentityId  : param.buyerIdentityId ;
  }
  visible.value = true;
  form.transactionId = param.id;
  form.transactionType = type;
  form.identityFiles = [];
  form.otherFiles = [];
  form.companyFiles = [];
  form.shareholderFiles = [];
  form.proxyFiles = [];

  // 重置表单数据
  nextTick(() => {
    form.fileId > 0 ? '' : form.fileId = null;
    dataFormRef.value?.resetFields();
  });
  if  ( (type === 'listing'  || type === 'auction' )&& param.buyerIdentityId > 0) {
    form.fileId = param.buyerIdentityId;
  }
  if (form.fileId > 0 ) {
    console.log(222)
    getAgreementTradeFileData(form.fileId);
  }
};

// 提交
const onSubmit = async () => {
  const valid = await dataFormRef.value.validate().catch(() => {});
   console.log(valid);
  if (!valid) return false;
  console.log(param_data)
  if(param_data!=null && param_data.identityFiles!=null  && param_data.identityFiles!=undefined)
  {
    for (let arra_val  in param_data.identityFiles)
    {
      let url=param_data.identityFiles[arra_val].url;
      let result= form.identityFiles.find(obj => obj.url ==url );
      if (!result) {
        form.identityFiles.push(param_data.identityFiles[arra_val])
      }
    }
  }
  if(param_data!=null && param_data.proxyFiles!=null  && param_data.proxyFiles!=undefined)
  {
  for (let arra_val  in param_data.proxyFiles)
  {

    let url=param_data.proxyFiles[arra_val].url;
    let result= form.proxyFiles.find(obj => obj.url ==url );

    if (!result) {
      form.proxyFiles.push(param_data.proxyFiles[arra_val])
    }
  }
  }
  if(param_data!=null && param_data.companyFiles!=null && param_data.companyFiles!=undefined)
  {
  for (let arra_val  in param_data.companyFiles)
  {
    let url=param_data.companyFiles[arra_val].url;
    let result= form.companyFiles.find(obj => obj.url ==url );
    if (!result) {
      form.companyFiles.push(param_data.companyFiles[arra_val])
    }
  }
  }

  if(param_data!=null && param_data.shareholderFiles!=null && param_data.shareholderFiles!=undefined)
  {


  for (let arra_val  in param_data.shareholderFiles)
  {
    let url=param_data.shareholderFiles[arra_val].url;
    let result= form.shareholderFiles.find(obj => obj.url ==url );
    if (!result) {
      form.shareholderFiles.push(param_data.shareholderFiles[arra_val])
    }
  }

  }

  if(param_data!=null && param_data.otherFiles!=null  && param_data.otherFiles!=undefined)
  {
  for (let arra_val  in param_data.otherFiles)
  {
    let url=param_data.otherFiles[arra_val].url;
    let result= form.otherFiles.find(obj => obj.url ==url );
    if (!result) {
      form.otherFiles.push(param_data.otherFiles[arra_val])
    }
  }
  }
  if(param_data!=null && param_data.applyFiles!=null  && param_data.applyFiles!=undefined)
  {


  for (let arra_val  in param_data.applyFiles)
  {
    let url=param_data.applyFiles[arra_val].url;
    let result= form.applyFiles.find(obj => obj.url ==url );
    if (!result) {
      form.applyFiles.push(param_data.applyFiles[arra_val])
    }
  }
  }
  if(param_data!=null && param_data.identityCards!=null  && param_data.identityCards!=undefined)
  {


  for (let arra_val  in param_data.identityCards)
  {
    let url=param_data.identityCards[arra_val].url;
    let result= form.identityCards.find(obj => obj.url ==url );
    if (!result) {
      form.identityCards.push(param_data.identityCards[arra_val])
    }
  }
  }

  if(param_data!=null && param_data.landOutContract!=null   && param_data.landOutContract!=undefined)
  {


  for (let arra_val  in param_data.landOutContract)
  {
    let url=param_data.landOutContract[arra_val].url;
    let result= form.landOutContract.find(obj => obj.url ==url );
    if (!result) {
      form.landOutContract.push(param_data.landOutContract[arra_val])
    }
  }
  }

  if (param_data!=null && param_data.landEvaluateReport!=null  && param_data.landEvaluateReport!=undefined)
  {


  for (let arra_val  in param_data.landEvaluateReport)
  {
    let url=param_data.landEvaluateReport[arra_val].url;
    let result= form.landEvaluateReport.find(obj => obj.url ==url );
    if (!result) {
      form.landEvaluateReport.push(param_data.landEvaluateReport[arra_val])
    }
  }
  }

  if(param_data!=null && param_data.assetsEvaluateReport!=null  && param_data.assetsEvaluateReport!=undefined)
  {
    for (let arra_val  in param_data.assetsEvaluateReport)
    {
      let url=param_data.assetsEvaluateReport[arra_val].url;
      let result= form.assetsEvaluateReport.find(obj => obj.url ==url );
      if (!result) {
        form.assetsEvaluateReport.push(param_data.assetsEvaluateReport[arra_val])
      }
    }
  }

  if(param_data!=null && param_data.transferContract!=null  && param_data.transferContract!=undefined){

    for (let arra_val  in param_data.transferContract)
    {
      let url=param_data.transferContract[arra_val].url;
      let result= form.transferContract.find(obj => obj.url ==url );
      if (!result) {
        form.transferContract.push(param_data.transferContract[arra_val])
      }
    }


  }

if(param_data!=null &&  param_data.permitFiles!=null  &&  param_data.permitFiles!=undefined)
{


  for (let arra_val  in param_data.permitFiles)
  {
    let url=param_data.permitFiles[arra_val].url;
    let result= form.permitFiles.find(obj => obj.url ==url );
    if (!result) {
      form.permitFiles.push(param_data.permitFiles[arra_val])
    }
  }

}
if(param_data!=null && param_data.confirmFiles!=null  && param_data.confirmFiles!=undefined)
{

  for (let arra_val  in param_data.confirmFiles)
  {
    let url=param_data.confirmFiles[arra_val].url;
    let result= form.confirmFiles.find(obj => obj.url ==url );
    if (!result) {
      form.confirmFiles.push(param_data.confirmFiles[arra_val])
    }
  }
}




  if(param_data!=null && param_data.proofFiles!=null  && param_data.proofFiles!=undefined) {
    for (let arra_val in param_data.proofFiles) {
      let url = param_data.proofFiles[arra_val].url;
      let result = form.proofFiles.find(obj => obj.url == url);
      if (!result) {
        form.proofFiles.push(param_data.proofFiles[arra_val])
      }
    }
  }


if(param_data!=null && param_data.judicialFiles!=null  && param_data.judicialFiles!=undefined)
{


  for (let arra_val  in param_data.judicialFiles)
  {
    let url=param_data.judicialFiles[arra_val].url;
    let result= form.judicialFiles.find(obj => obj.url ==url );
    if (!result) {
      form.judicialFiles.push(param_data.judicialFiles[arra_val])
    }
  }
}











  try {
    loading.value = true;

    if (form.id  && form.id!="0") {
      CommonService.putAgreementTradeFiles(form,form.transactionType,form).then(res => {
        if (res && res.code === 0) {
          Message.success('修改资料成功');
        }else {
          Message.error(res.msg);
        }
      });
    } else {
      CommonService.addAgreementTradeFiles(form,form.transactionType,form).then(res => {
         if (res && res.code === 0) {
           Message.success('添加资料成功');
         }else {
           Message.error(res.msg);
         }
       });
    }
    visible.value = false;
  } catch (err) {

    Message.error(err.msg);
  } finally {
    loading.value = false;
  }
};

const getAgreementTradeFileData = (id) => {
  // 获取数据
  loading.value = true;
  CommonService.getAgreementTradeFiles(id).then((res) => {
    console.log(1111)
    console.log(res)
        if (res.data) {

          Object.assign(form, res.data);

          param_data=res.data;


        }
      }).finally(() => {
        loading.value = false;
      });
};



// 暴露变量
defineExpose({
  openDialog,
});
</script>
<style>
.form-item-desc {
  font-size: 12px;
  color: red;
}
.min-width{
  width: 1300px;
}
.upload-file{
  width: 150px;
}
.navWrap_center_ul {
  width: 150px;
}
</style>
